import React from "react"
import tw, { styled } from "twin.macro"
import * as api from "../apiConnection"
import dayjs from "dayjs"
import { Link } from "gatsby"

import * as utils from "../utils"
import CalendarIcon from "./CalendarIcon"
import ClockIcon from "./ClockIcon"
import ArrowIcon from "./ArrowIcon"
import * as data from "../courseData"

const Container = styled.div`
  ${tw`max-w-3xl my-4 mx-auto bg-white shadow overflow-hidden rounded-md`}
`

const StyledLink = styled(Link)`
  ${tw`block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out`}
`

const ContentContainer = styled.div`
  ${tw`px-4 py-4 flex items-center sm:px-6`}
`

const ContentInnerContainer = styled.div`
  ${tw`min-w-0 flex-1 sm:flex sm:items-center sm:justify-between`}
`

const IconContainer = styled.div`
  ${tw`ml-5 flex-shrink-0`}
`

const Title = styled.div`
  ${tw`text-sm leading-5 font-medium text-blue-700 truncate`}
`

const TitleSpan = styled.span`
  ${tw`font-normal text-gray-500`}
`

const DetailsContainer = styled.div`
  ${tw`mt-2 md:flex`}
`

const DaysAndPrice = styled.div`
  ${tw`flex`}
`

const DetailsInnerContainer = styled.div`
  ${tw`flex items-center text-sm leading-5 text-gray-500`}
`

const ListItem = styled.li`
  ${tw`border-t border-gray-200`}
`

const LineWrapper = styled.div`
  ${tw`flex flex-col md:flex-row md:items-center justify-between`}
`

const LineWrapperContainer = styled.div`
  ${tw`flex-1`}
`

const PriceContainer = styled.div`
  ${tw`flex-1 text-right text-sm leading-5 font-medium`}
`

const DateWrapper = styled.div`
  ${tw`mr-7 mt-2`}
`

const HoursSpan = styled.span`
  ${tw`text-gray-500`}
`

const NewGrossPrice = styled.span`
  ${tw`text-gray-500 line-through`}
`

const Weekdays = styled.div`
  ${props =>
    props.highlight && tw`text-blue-700 text-sm leading-5 font-medium`}
`

const getTimePhrase = (oddHours, evenHours) => {
  if (
    oddHours.hour_from === evenHours.hour_from &&
    oddHours.hour_to === evenHours.hour_to
  ) {
    return `w godzinach ${oddHours.hour_from} do ${oddHours.hour_to}`
  }
  return `w godzinach ${oddHours.hour_from} do ${oddHours.hour_to}, weekendy parzyste ${evenHours.hour_from} do ${evenHours.hour_to}`
}

const CourseTermsList = ({ extended, idFilter }) => {
  const [courseList, setCourseList] = React.useState(null)
  React.useEffect(() => {
    api.getCurrentCourses().then(courses => {
      let filtered = courses
      if (idFilter) {
        filtered = filtered.filter(course => {
          return course._id === idFilter
        })
      }
      setCourseList(filtered)
    })
  }, [idFilter])

  return (
    <Container>
      <ul>
        {courseList &&
          courseList.map(course => {
            const term = course.terms
            const startDate = dayjs(term.date_from)
            const endDate = dayjs(term.date_to)
            const courseStaticData =
              data[Object.keys(data).find(key => data[key].id === course._id)]
            return (
              <ListItem key={term._id}>
                <StyledLink to={extended ? `/formularz-zgloszeniowy-na-kurs/?termin=${term._id}` : courseStaticData.link}>
                  <ContentContainer>
                    <ContentInnerContainer>
                      <LineWrapperContainer>
                        {!extended && (
                          <LineWrapper>
                            <Title>
                              <TitleSpan>
                                {courseStaticData.title.main}&nbsp;
                              </TitleSpan>
                              {courseStaticData.title.second}
                            </Title>
                          </LineWrapper>
                        )}
                        <DaysAndPrice>
                          <LineWrapperContainer>
                            <LineWrapper>
                              <DetailsInnerContainer>
                                <Weekdays highlight={extended}>
                                  {term.weekdays
                                    .map(day => utils.getDayName(day))
                                    .join(" i ")}
                                </Weekdays>
                              </DetailsInnerContainer>
                              {!extended && (
                                <PriceContainer>
                                  <HoursSpan>{course.hours}&nbsp;
                                  {utils.getHoursPhrase(course.hours)}, &nbsp;</HoursSpan>
                                  {courseStaticData.price.new_gross !== courseStaticData.price.gross && <NewGrossPrice>{courseStaticData.price.new_gross}{" "}</NewGrossPrice>}
                                  <strong>{courseStaticData.price.gross} zł</strong>
                                </PriceContainer>
                              )}
                            </LineWrapper>
                          </LineWrapperContainer>
                        </DaysAndPrice>

                        <DetailsContainer>
                          <DateWrapper>
                            <DetailsInnerContainer>
                              <CalendarIcon />
                              <span>
                                Start
                                <time dateTime={startDate.format("YYYY-MM-DD")}>
                                  {" "}
                                  {`${startDate.format(
                                    "DD"
                                  )} ${utils.getMonthName(
                                    startDate.format("MM")
                                  )}, ${startDate.format("YYYY")}`}
                                </time>
                              </span>
                            </DetailsInnerContainer>
                          </DateWrapper>
                          {extended && (
                            <DateWrapper>
                              <DetailsInnerContainer>
                                <CalendarIcon />
                                <span>
                                  Koniec
                                  <time dateTime={endDate.format("YYYY-MM-DD")}>
                                    {" "}
                                    {`${endDate.format(
                                      "DD"
                                    )} ${utils.getMonthName(
                                      endDate.format("MM")
                                    )}, ${endDate.format("YYYY")}`}
                                  </time>
                                </span>
                              </DetailsInnerContainer>
                            </DateWrapper>
                          )}
                        </DetailsContainer>
                        {extended && (
                          <DetailsContainer>
                            <DetailsInnerContainer>
                              <ClockIcon />
                              {getTimePhrase(term.odd_hours, term.even_hours)}
                            </DetailsInnerContainer>
                          </DetailsContainer>
                        )}
                        {/* <DetailsContainer>
                            <DetailsInnerContainer>
                              {course.hours} godzin, &nbsp;
                              <strong>{course.price.gross} zł</strong>
                            </DetailsInnerContainer>
                          </DetailsContainer> */}
                      </LineWrapperContainer>
                    </ContentInnerContainer>
                    <IconContainer>
                      <ArrowIcon />
                    </IconContainer>
                  </ContentContainer>
                </StyledLink>
              </ListItem>
            )
          })}
      </ul>
    </Container>
  )
}

export default CourseTermsList
