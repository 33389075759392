import * as React from "react"
import tw, { styled } from "twin.macro"

const BiggerTextContainer = styled.div`
  ${tw`text-lg max-w-prose mx-auto mb-6`}
`

const Header = styled.h1`
  ${tw`mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10`}
`

const BiggerText = styled.p`
  ${tw`text-xl text-gray-500 leading-8`}
`

const ContentBiggerText = ({ header, text }) => {
    return (
        <BiggerTextContainer>
          {header && <Header>{header}</Header> }
          <BiggerText>
            {text}
          </BiggerText>
        </BiggerTextContainer>
    )
}

export default ContentBiggerText
