export const basic = {
  id: "553ab15f82d946ec1402a202",
  price: {
    gross: 3000,
    net: 2439.02,
    new_gross: 3000,
  },
  hours: 30,
  name: "Księgowość od podstaw",
  title: {
    main: "Kurs księgowości",
    second: "od podstaw",
  },
  link: "/kursy-ksiegowosci/",
}

export const advanced = {
  id: "553ab5272f523e1b1551e3fb",
  price: {
    gross:  3500,
    net:  2845.53,
    new_gross:  3500,
  },
  hours: 30,
  name: "Księgowość dla zaawansowanych",
  title: {
    main: "Kurs księgowości",
    second: "dla zaawansowanych",
  },
  link: "/kurs-samodzielny-ksiegowy/",
}

export const hr = {
  id: "553ab0d1448538d914b5b8d0",
  price: {
    gross: 1400,
    net: 1138.21,
    new_gross: 1400,
  },
  hours: 64,
  name: "Kadry i płace",
  title: {
    main: "Kurs",
    second: "kadry i płace",
  },
  link: "/kurs-kadry-i-place/",
}

export const small = {
  id: "553ab5a3dfc6a5321537b6c3",
  price: {
    gross: 2000,
    net:  1626.02,
    new_gross: 2000,
  },
  hours: 20,
  name: "Księgowość małych firm",
  title: {
    main: "Kurs",
    second: "księgowość małych firm",
  },
  link: "/kurs-ksiegowosci-malych-firm/",
}
