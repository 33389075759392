import * as React from "react"
import tw, { styled } from "twin.macro"

const SmallerTextContainer = styled.div`
  ${tw`prose prose-lg text-gray-500 mx-auto`}
`

const ContentSmallerText = ({ text }) => {
    return (
        <SmallerTextContainer>
        <p>
          {text}
        </p>
      </SmallerTextContainer>
    )
}

export default ContentSmallerText
